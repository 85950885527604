<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Кориснички сервис БеоТаблет – <a href="tel:0604064777">060/4064777</a>
    </span>

    <span class="float-md-right d-md-block">Подршка: 
      <a href="mailto:podrska@beotablet.rs">podrska@beotablet.rs</a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
